import React, { useState } from 'react'
import { Grid, Typography } from '@mui/material'

const Feedback = () => {
  const [message] = useState('')

  const navBarStyle = {
    position: 'fixed',
    top: 0,
    width: '100%',
    height: '56px',
    borderBottom: '1px solid #E5E5E5',
    boxSizing: 'border-box',
    background: 'white',
    padding: '0 38px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '-40px'
  }

  return (
    <Grid sx={navBarStyle}>
      <img
        style={{ width: '149px', height: '25px', paddingRight: 0 }}
        src={'/MathisonColourLogo.png'}
        alt='Mathison Logo'
      />
      <a
        style={{ textDecoration: 'none' }}
        href={`mailto:support@mathison.io?subject=Mathison Product Feedback&body=${encodeURIComponent(
          message
        )}`}
      >
        <Typography
          sx={{
            cursor: 'pointer',
            marginRight: '8em',
            fontWeight: 700,
            color: '#050B0E',
            letterSpacing: '2.04px',
            textTransform: 'uppercase'
          }}
        >
          Feedback
        </Typography>
      </a>
    </Grid>
  )
}

export default Feedback
